import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  public innerWidth: any;

  private subscription: Subscription;
  
  public dateNow = new Date();
  public dDay    = new Date('Dec 31 2024 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay      = 24;
  minutesInAnHour  = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference () {
      this.timeDifference = this.dDay.getTime() - new  Date().getTime();
      this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits (timeDifference) {
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond  * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday   = Math.floor((timeDifference) / (this.milliSecondsInASecond  * this.minutesInAnHour  * this.SecondsInAMinute) % this.hoursInADay);
      this.daysToDday    = Math.floor((timeDifference) / (this.milliSecondsInASecond  * this.minutesInAnHour  * this.SecondsInAMinute  * this.hoursInADay));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor() { }
  
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.subscription = interval(1000)
        .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

// Jan
// Feb
// Mar
// Apr
// May	
// June	
// July	
// Aug	
// Sept
// Oct
// Nov
// Dec
