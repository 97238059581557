<!-- div style="height: 100vh"> -->
    <!--header class="resposive"-->
      <div id="intro" class="view">
        <video id="vid" class="video-intro" poster="https://clubb2b.com.br/images/main/background.jpg" 
          allowfullscreen playsinline oncanplay="this.play()" onloadedmetadata="this.muted=true" loop
          style="min-width: 100%; min-height: 100%;">
          <source src="https://clubb2b.com.br/videos/Pills.mp4" type="video/mp4">
        </video>
        <div class="mask rgba-gradient d-md-flex justify-content-center align-items-center overflow-auto">
          <div class="container px-md-3 px-sm-0">
            <div class="row wow fadeIn">
              <div class="col-md-12 mb-4 white-text text-center mb-0 mt-5 pt-md-5 pt-5 wow fadeIn">
                <h1 class="display-3 font-weight-bold sombra">
                  <a class="font-weight-bold teal-text sombra">Farmá</a>
                  <a class="font-weight-bold text-warning sombra">cias</a>
                  <a class="font-weight-bold text-primary sombra"> JÁ</a>
                </h1>
                <h1 class="font-weight-bold white-text sombra">
                  Seu aplicativo de Delivery 
                  <a class="font-weight-bold text-success sombra">B</a> 
                  <a class="font-weight-bold text-warning sombra">2</a>
                  <a class="font-weight-bold text-primary sombra">C</a>
                  do setor Pharma  
                </h1>
                <hr class="hr-light my-4 w-95">
                <h4 class="subtext-header mt-2 mb-4 text-justify">
                  Amplie seus horizontes, quebre paradigmas, expanda a carteira de negócios da sua empresa, esteja online
                  e conquiste maior visibilidade de sua marca, aumente a sua rede de negócios
                  <a class="font-weight-bold text-success sombra">B</a>
                  <a class="font-weight-bold text-warning sombra">2</a>
                  <a class="font-weight-bold text-primary sombra">C</a>.
                  <p>Nós da 
                    <a class="font-weight-bold text-success sombra">Tecno</a>
                    <a class="font-weight-bold text-warning sombra">pharma</a>
                    <a class="font-weight-bold text-primary sombra"> Group</a>,
                    geramos novas oportunidades de negócios para você e para seus parceiros, ampliando sua rede de negócios
                    e criando novas parcerias, aumentando assim a competitividade de sua empresa no mercado de atuação da
                    mesma e em novos mercados.
                    No mundo 
                    <a class="font-weight-bold text-success sombra">Tecno</a>
                    <a class="font-weight-bold text-warning sombra">pharma</a>
                    <a class="font-weight-bold text-primary sombra"> Group</a>, 
                    tudo é feito pensando em geração e manutenção de negócios.
                  </p>
                  <p class="font-weight-bold text-center sombra">
                    Em breve estaremos online para servi-los!
                  </p>
                  <p class="font-weight-bold text-center sombra">
                    <a class="font-weight-bold text-danger sombra">contato:</a> 
                    <a class="font-weight-bold black-text sombra">
                      contato@tecnopharmagroup.com.br
                    </a>
                  </p>                  
                  <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE EM CONSTRUÇÃO
                  </h1>
                  <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE EM CONSTRUÇÃO
                  </h1>
                  <div *ngIf="innerWidth > 414" class="timer">
                    <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                  <div *ngIf="innerWidth <= 414" class="timer-mobile">
                    <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                </h4> 
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--/header-->
<!-- </div> -->
